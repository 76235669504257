export const HAPPYCALL_LIST_SUCCESS = "HAPPYCALL_LIST_SUCCESS";
export const HAPPYCALL_LIST_CONCAT_SUCCESS = "HAPPYCALL_LIST_CONCAT_SUCCESS";
export const HAPPYCALL_LIST_LOADING = "HAPPYCALL_LIST_LOADING";
export const HAPPYCALL_LIST_ERROR = "HAPPYCALL_LIST_ERROR";
export const HAPPYCALL_LIST_CONCAT_LOADING = "HAPPYCALL_LIST_CONCAT_LOADING";



export const HAPPYCALL_DETAIL_SUCCESS = "HAPPYCALL_DETAIL_SUCCESS";
export const HAPPYCALL_DETAIL_LOADING = "HAPPYCALL_DETAIL_LOADING";
export const HAPPYCALL_DETAIL_ERROR = "HAPPYCALL_DETAIL_ERROR";
