export const BOARD_SUCCESS = "BOARD_SUCCESS";
export const BOARD_LOADING = "BOARD_LOADING";
export const BOARD_FAIL = "BOARD_FAIL";
export const BOARD_CONCAT_SUCCESS = "BOARD_CONCAT_SUCCESS";
export const BOARD_CONCAT_LOADING = "BOARD_CONCAT_LOADING";
export const BOARD_INIT = "BOARD_INIT";

//공지사항 공지 
export const BOARD_TOP_SUCCESS = "BOARD_TOP_SUCCESS";
export const BOARD_TOP_LOADING = "BOARD_TOP_LOADING";
export const BOARD_TOP_FAIL = "BOARD_TOP_FAIL";

export const BOARD_DETAIL_SUCCESS = "BOARD_DETAIL_SUCCESS";
export const BOARD_DETAIL_LOADING = "BOARD_DETAIL_LOADING";
export const BOARD_DETAIL_FAIL = "BOAR_DETAILD_FAIL";


export const BOARD_POST_SUCCESS = "BOARD_POST_SUCCESS";
export const BOARD_POST_LOADING = "BOARD_POST_LOADING";
export const BOARD_POST_ERROR = "BOARD_POST_ERROR";
export const BOARD_POST_INPUT = "BOARD_POST_INPUT";
export const BOARD_POST_LOGIN_INPUT = "BOARD_POST_LOGIN_INPUT";





export const BOARD_POST_MODIFY_SUCCESS = "BOARD_POST_MODIFY_SUCCESS";
export const BOARD_POST_MODIFY_LOADING = "BOARD_POST_MODIFY_LOADING";
export const BOARD_POST_MODIFY_ERROR = "BOARD_POST_MODIFY_ERROR";
export const BOARD_POST_MODIFY_INPUT = "BOARD_POST_MODIFY_INPUT";
export const BOARD_POST_MODIFY_LOGIN_INPUT = "BOARD_POST_MODIFY_LOGIN_INPUT";
