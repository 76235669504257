import axios from 'axios'
import {loginError, loginLoading, loginSuccess} from '../actionFn/auth';
import { useHistory } from "react-router-dom";

export const login = (userid,password,token="not Token",uuid="not UUID",autologin=false) => dispatch  => {
    dispatch(loginLoading());
    
    const url = '/BM/API/auth/login.asp';
        axios.post(url, {
            userid: userid,
            password: password,
            token:token,
            uuid:uuid
        }).then(function (res) {
            if(res.data.result === "success"){
                dispatch(loginSuccess(res.data.user));
                if(res.data.user.man_info_sn !== undefined && res.data.user.man_info_sn !== ""){
                    localStorage.setItem('user', JSON.stringify(res.data.user));
                    localStorage.setItem('auto_login', autologin);
                }
            }else if(res.data.result === "fail"){
                alert(res.data.message);
                dispatch(loginError(res.data));
            }

            
            console.log(JSON.parse(localStorage.getItem('user')));
             // response  
        }).catch(function (error) {
            console.log(error);
            dispatch(loginError(error));
        });
}