 export const WORKINGDAY_SUCCESS = "WORKINGDAY_SUCCESS";
 export const WORKINGDAY_ERROR = "WORKINGDAY_ERROR";
 export const WORKINGDAY_LOADING = "WORKINGDAY_LOADING";



 export const WORKINGDAY_DETAIL_SUCCESS = "WORKINGDAY_DETAIL_SUCCESS";
 export const WORKINGDAY_DETAIL_ERROR = "WORKINGDAY_DETAIL_ERROR";
 export const WORKINGDAY_DETAIL_LOADING = "WORKINGDAY_DETAIL_LOADING";



 export const WORKINGDAY_FORM_SUCCESS = "WORKINGDAY_FORM_SUCCESS";
 export const WORKINGDAY_FORM_LOADING = "WORKINGDAY_FORM_LOADING";
 export const WORKINGDAY_FORM_ERROR = "WORKINGDAY_FORM_ERROR";
 export const WORKINGDAY_FORM_INPUT = "WORKINGDAY_FORM_INPUT";
 export const WORKINGDAY_FORM_LOGIN_INPUT = "WORKINGDAY_FORM_LOGIN_INPUT";