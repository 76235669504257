export const BILL_LIST_SUCCESS = "BILL_LIST_SUCCESS";
export const BILL_LIST_LOADING = "BILL_LIST_LOADING";
export const BILL_LIST_ERROR = "BILL_LIST_ERROR";

export const BILL_DETAIL_SUCCESS = "BILL_DETAIL_SUCCESS";
export const BILL_DETAIL_LOADING = "BILL_DETAIL_LOADING";
export const BILL_DETAIL_ERROR = "BILL_DETAIL_ERROR";

export const BILL_PAY_SUCESS = "BILL_PAY_SUCCESS";
export const BILL_PAY_ERROR = "BILL_PAY_ERROR";

