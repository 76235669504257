import axios from "axios";
import { getBillDetailError, getBillDetailLoading, getBillDetailSuccess, getBillListError, getBillListLoading, getBillListSuccess, getPaySuccess,getPayError } from "../actionFn/bill";

const user = JSON.parse(localStorage.getItem('user'));       



export const getBillList = () => dispatch =>{
    dispatch(getBillListLoading());
    const url = '/BM/API/seller/bill_list.asp';
    axios.post(url,{
        "man_info_sn": user.man_info_sn
    }).then(function (res){
        dispatch(getBillListSuccess(res.data));
    }).catch(function (error){
        dispatch(getBillListError(error));
    })
}

export const getBillDetail = () => dispatch =>{
    dispatch(getBillDetailLoading());
    const url = '/BM/API/seller/bill_info.asp';
    axios.post(url,{
        "brand":user.brand,
        "man_info_sn":user.man_info_sn,
        "manname":user.name,
        "teamname":user.teamname
    }).then(function (res){
        dispatch(getBillDetailSuccess(res.data));
    }).catch(function (error){
        dispatch(getBillDetailError(error));
    })
}

export const sendBillInfo = (good_mny,pay_method,pg) => dispatch =>{
    dispatch(getBillDetailLoading());
    const url = '/BM/API/kcp/kcp_result.asp';
    console.log("PAY SEND INFO");
    console.log(" GOOD MNY " + good_mny);
    console.log(" pay_method " + pay_method);
    console.log(" man_info_sn " + user.man_info_sn);
    console.log(" pg " + user);
    axios.post(url,{
        "brand":user.brand,
        "man_info_sn":user.man_info_sn,
        "manname":user.name,
        "teamname":user.teamname,
        "good_mny":good_mny,
        "buyr_name":user.name,
        "use_pay_method":pay_method,
        "pg_corp":pg,
        "userid":user.userid,
    }).then(function (res){
        if(res.data.result === "success"){
            alert(res.data.message);
            console.log("PAY SEND INFO - SUCCESS");
            dispatch(getPaySuccess(res.data));
            window.document.location.reload();
        }else{
            alert(res.data.message);
            console.log("PAY SEND INFO - FAILED");
            dispatch(getPayError(res.data));
        }
        
        
    }).catch(function (error){
        console.log("PAY SEND INFO - FAIL");
        dispatch(getBillDetailError(error));
    })    
}
