export const API_SUCCESS = "API_SUCCESS";
export const API_LOADING = "API_LOADING";
export const API_ERROR = "API_ERROR";
export const API_INIT = "API_INIT";

export const API_AN_SUCCESS = "API_AN_SUCCESS";
export const API_AN_LOADING = "API_AN_LOADING";
export const API_AN_ERROR = "API_AN_ERROR";
export const API_AN_INIT = "AP_ANI_INIT";

export const API_LIST_SUCCESS = "API_LIST_SUCCESS";
export const API_LIST_LOADING = "API_LIST_LOADING";
export const API_LIST_ERROR = "API_LIST_ERROR";
export const API_LIST_INIT = "API_LIST_INIT";

export const API_LIST_CONCAT_SUCCESS = "API_LIST_CONCAT_SUCCESS";
export const API_LIST_CONCAT_LOADING = "API_LIST_CONCAT_LOADING";
export const API_LIST_CONCAT_ERROR = "API_LIST_CONCAT_ERROR";
export const API_LIST_CONCAT_INIT = "API_LIST_CONCAT_INIT";


export const API_DATA_SUCCESS = "API_DATA_SUCCESS";
export const API_DATA_LOADING = "API_DATA_LOADING";
export const API_DATA_ERROR = "API_DATA_ERROR";
export const API_DATA_INIT = "API_DATA_INIT";


export const API_ANDATA_SUCCESS = "API_ANDATA_SUCCESS";
export const API_ANDATA_LOADING = "API_ANDATA_LOADING";
export const API_ANDATA_ERROR = "API_ANDATA_ERROR";
export const API_ANDATA_INIT = "API_ANDATA_INIT";