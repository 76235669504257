import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: ${(props) => props.width || "100%"};
  background:  ${(props) => props.bg || "white"};
  padding: 15px 16px 15px 16px;
`
const Title = styled.div`
  font-size: ${(props) => props.theme.fontSizes.xl};
  letter-spacing: -0.66px;
  font-weight: bold;
  margin-bottom:8px;
  text-align: center;
`
const Subtit = styled.div`
  font-weight: bold;
`
const Desc = styled.div`
    padding-bottom:8px;
    table{
        border: 1px solid #acacac;
        width: 100%;
        text-align: center;
        letter-spacing: -0.5px;
        vertical-align: middle;
        th{
            background: #ebebeb;
        color: #444444;
        font-weight: normal;
        }
        th, td{
        border: 1px solid #acacac;
        }
    }
`
function Privacy() {
  return (
    <Wrapper>
        <Title>개인정보처리방침</Title>
        <Desc>
        케이지비(주)는 이용자(고객)의 개인정보를 매우 중요시 여기며, ‘정보통신망이용촉진 및 정보보호 등에 관한 법률’및 ‘개인정보보호법’상의 개인정보보호 규정을 준수하고 있습니다.<br/>
케이지비(주)는 이용자께서 동의하고 제공한 개인정보가 어떠한 목적으로 이용되고 있으며 어떤 절차에 따라 처리되는지에 대하여 다음과 같은 처리방침을 두고 있습니다.
        </Desc>
        <Subtit>1.개인정보 수집 및 이용 목적</Subtit>
        <Desc>
        1-1. KGB 홈페이지는 비회원제로 운영되며 포장이사, 대리점 소개 등 KGB 홈페이지 서비스를 자유롭게 이용할 수 있으나, 이용자가 서비스(이사견적 내기, 무료방문 견적신청, 전화상담 신청 등)를 이용하기 위하여 실명제로 운영되며 케이지비(주)는 필요한 최소한의 개인정보를 수집합니다.<br/>
        1-2.케이지비(주)는 이사서비스 제공 및 품질향상, 고객불만 처리, 광고 홍보 등 아래의 목적으로 개인정보를 이용합니다.<br/>
        1-2-1. 이용자가 제공한 개인정보를 바탕으로 보다 정확한 이사 견적가를 산출하기 위해 견적관리자의 견적산출관리의 용도로 이용합니다.<br/>
        1-2-2. 이용자가 제공한 개인정보는 이용자와 계약당사자인 가맹점사업자(대리점)에서 견적방문과 계약체결 및 이사를 진행할 수 있도록 하기 위한 목적으로 이용합니다.<br/>
        1-2-3. 이용자가 제공한 개인정보는 계약이행 후 불만 및 개선사항 접수를 위한 해피콜, A/S 처리, 이사 후 이용자가 평가글을 작성할 수 있도록 하기위한 이용자 확인의 목적으로 이용합니다.<br/>
        1-2-4. 이용자가 제공한 개인정보는 고객 동의하에 KGB물류그룹의 브랜드에 공유되어 더 유용한 서비스를 이용자에게 제공할 목적으로 이용합니다.<br/>
        1-2-5. 이용자가 제공한 개인정보는 KGB 서비스와 관련된 뉴스, 이벤트, 업데이트 정보, 이사 안내 등 KGB 서비스를 보다 원활하게 제공하기 위한 홍보 광고의 목적(이메일, 문자 등)으로 이용합니다.<br/>
        1-2-6. 이용자가 제공한 개인정보는 서비스 이용 기록, 서비스 이용 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 서비스 개선 목적으로 이용합니다.<br/>
        </Desc>
        <Subtit>2. 수집하는 개인정보의 항목 및 수집방법</Subtit>
        <Desc>
        2-1. 이사서비스를 제공받기 위해 케이지비(주)가 수집하는 개인정보는 아래와 같습니다.<br/>
        2-1-1. 필수 정보<br/>
        (1) 성명<br/>
        (2) 주소(이사 출발지 및 도착지)<br/>
        (3) 전화번호(집 또는 사무실, 휴대전화 등)<br/>
        (4) 이사일<br/>
        2-1-2. 기타 이사서비스 제공에 필요한 선택정보<br/>
        (1) 이용동기<br/>
        (2) 이사물량 산출 관련 항목-주거 형태, 가족 수, 이사화물 목록 등<br/>
        (3) 이메일 주소<br/>
        2-2. 케이지비(주)는 사상, 신념, 가족 및 친인척관계, 학력(學歷)·병력(病歷), 기타 사회활동 경력 등 개인의 권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는 개인정보를 수집하지 않습니다. 다만, 이용자의 동의를 받거나 다른 법률에 따라 특별히 수집 대상 개인정보로 허용된 경우에는 필요한 범위에서 최소한으로 그 개인정보를 수집할 것입니다.<br/>
        2-3. 서비스 이용과정에서 IP주소, 쿠키, 방문일시 등의 서비스 이용 기록이 생성 수집될 수 있습니다.<br/>
        2-3-1. 쿠키(웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터에 저장)는 이용자에게 보다 빠르고 편리한 웹사이트 사용을 지원하고 맞춤형 서비스를 제공하기 위해 사용됩니다.<br/>
        2-3-2. 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수도 있습니다.<br/>
        2-4. 케이지비(주)는 아래의 방법을 통해 개인정보를 수집합니다.<br/>
        2-4-1. 서비스 이용과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우 해당정보 수집(인터넷 사이트에 이용자가 케이지비(주)의 개인정보처리방침 동의여부에 대하여 「예」또는 「아니오」를 선택할 수 있는 절차를 마련하여, 「예」를 선택하면 개인정보 수집에 대해 동의한 것으로 봄)<br/>
        2-4-2. 고객센터를 통한 상담 과정에서 메일, 팩스, 전화 등을 통해 이용자의 개인정보 수집(고객 문의 전화 과정에서 전화번호는 자동으로 생성되어 수집될 수 있음)<br/>
        2-4-3. 케이지비(주) 가맹점사업자(대리점)를 통해 직접 이사서비스를 제공받는 경우 이사서비스 품질 평가 및 A/S 관리 등의 필요성이 인정되는 경우 해당 가맹점사업자(이 경우, 정보주체인 이용자의 요구가 있는 경우 개인정보의 수집 출처 및 처리 목적을 알려줌) 또는 이용자로부터 개인정보 수집<br/>
        </Desc>
        <Subtit>3. 개인정보의 제3자 제공</Subtit>
        <Desc>
        3-1. 케이지비(주)는 원칙적으로 이용자의 사전 동의 없이 개인정보를 외부에 제공하지 않습니다. 다만, 이용자가 정보제공에 동의를 한 경우, 관련 법령에 의거해 개인정보 제출의무가 발생한 경우에 한하여 개인정보를 제공하고 있습니다.<br/>
        3-2. 이용자의 개인정보를 제3자에게 제공하는 경우는 아래와 같습니다.<br/>
        3-2-1. 이용자로부터 별도의 동의를 받은 경우<br/>
        (1) 가맹점사업자(이사팀)에 이사견적 및 계약, 이사서비스 제공을 위한 경우<br/>
        (2) KGB물류그룹 내 타브랜드에 서비스를 제공하는 경우<br/>
        (3) 협력업체(청소, 에어컨 등)에 관련 서비스 제공을 위한 경우<br/>
        (4) 기타 이용자의 동의가 있는 경우<br/>
        3-2-2. 법률에 규정이 있는 경우<br/>
        (1) 이용자 또는 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 이용자 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우<br/>
        (2) 통계 작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아 볼 수 없는 형태로 개인정보를 제공하는 경우<br/>
        (3) 기타 법률에 특별한 규정이 있는 경우<br/>
        3-3. 개인정보를 제3자에게 제공하는 사항은 아래와 같습니다.<br/>
        <table border="0" cellpadding="0" cellspacing="0">
			<thead>
				<tr>
					<th width="227px">제공받는자</th>
					<th width="140px">이용목적</th>
					<th width="173px">제공항목</th>
					<th width="215px">개인정보 보유 및 이용기간</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>케이지비(주)  KGB, yes2404-대리점 및 이사팀
					</td><td>포장이사서비스</td>
					<td>성명,주소,전화번호,이사일</td>
					<td>1. 개인정보 수집 및 이용 목적 범위 내</td>
				</tr>
				<tr>
					<td>예스2424(주)(yes2424, Ycap-이사팀(호) 포함)
					</td><td>포장이사서비스</td>
					<td>성명,주소,전화번호,이사일</td>
					<td>1. 개인정보 수집 및 이용 목적 범위 내</td>
				</tr>
				<tr>
					<td>청소/에어컨 협력업체
					</td><td>청소 및 에어컨 설치 서비스</td>
					<td>성명,주소,전화번호,이사일</td>
					<td>1. 개인정보 수집 및 이용 목적 범위 내</td>
				</tr>
			</tbody>
		</table>
        </Desc>
        <Subtit>4. 개인정보의 제공 및 위탁</Subtit>
        <Desc>
        4-1. 케이지비(주)는 편리하고 더 나은 서비스를 제공하기 위해 업무 중 일부를 외부에 위탁하고 있으며, 위탁받은 업체가 위탁목적 범위 내에서 개인정보를 안전하게 처리하도록 필요한 사항을 규정하고 보호감독을 하고 있습니다.<br/>
        4-2. 개인정보의 수탁자 및 위탁업무 내용 등은 아래와 같습니다.
        <table border="0" cellpadding="0" cellspacing="0">
			<thead>
				<tr>
					<th width="117px">수탁자</th>
					<th width="290px">위탁업무 내용</th>
					<th width="330px">위탁계약 종료시</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>빅코리아
					</td><td>서비스시스템 개발/유지보수</td>
					<td>위탁계약 종료시(또는 해당 업무 목적 달성시)</td>
				</tr>
				<tr>
					<td>주식회사 KT
					</td><td>문자서비스 전송시스템 운영</td>
					<td>위탁계약 종료시(또는 해당 업무 목적 달성시)</td>
				</tr>
				<tr>
					<td>NICE평가정보(주)
					</td><td>본인확인</td>
					<td>해당업체에서 이미 개인정보 보유하여 별도 저장하지 않음</td>
				</tr>
				<tr>
					<td>예스2424(주)
					</td><td>고객지원센터 업무(이사서비스 및 관련 부가서비스)</td>
					<td>위탁계약 종료시(또는 해당 업무 목적 달성시)</td>
				</tr>
			</tbody>
		</table>
        </Desc>
        <Subtit>5. 개인정보 보유 및 이용기간/개인정보 파기절차 및 방법</Subtit>
        <Desc>
        5-1. 케이지비(주)는 개인정보 수집 및 이용목적 범위 내에서 이용자의 개인정보를 계속적으로 보유합니다.<br/>
        5-2. 개인정보의 보유 및 이용기간이 끝나거나 이용목적을 달성한 경우, 이용자의 개인정보의 수집, 이용, 제공 등의 동의 철회가 있는 경우 개인정보는 지체없이 재생이 불가능한 방법으로 파기합니다. 다만, 이용자에게 개인정보 보관 기간에 대해 별도의 동의를 얻은 경우, 법령에 따라 일정기간 개인정보를 보존하여야 하는 경우에는 안전하게 보관합니다(‘전자상거래 등에서의 소비자보호에 관한 법률’에 의한 계약 또는 청약철회 등에 관한 기록 5년, 소비자의 불만 또는 분쟁처리에 관한 기록 3년 경우 등).<br/>
        </Desc>
        <Subtit>6. 이용자의 권리와 그 행사에 관한 사항</Subtit>
        <Desc>
        6-1. 이용자(14세 미만 아동의 경우 법정대리인 포함)는 언제든지 아래의 개인정보 보호 관련 권리를 행사할 수 있습니다.<br/>
        6-1-1. 개인정보의 수집, 이용, 제공 등의 동의 철회(삭제 요구)<br/>
        6-1-2. 개인정보 열람, 제공, 오류의 정정 요구<br/>
        6-2. 이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.<br/>
        6-3. 만 14세 미만의 아동으로부터 개인정보 수집·이용·제공 등의 동의를 받아야 하는 경우 법정대리인의 동의를 받도록 하겠습니다. 이 경우 그 아동에게 법정대리인의 동의를 받기 위하여 필요한 법정대리인의 성명 등 최소한의 정보를 요구할 수 있습니다.<br/>
        6-4. 위의 권리행사는 전자우편, 서면, 팩스, 전화 등을 통하여 할 수 있으며, 이 경우 권리자의 본인 또는 정당한 대리인인지를 확인합니다(성명, 전화번호 등).<br/>
        </Desc>
        <Subtit>7. 개인정보 관련 기술적,관리적 대책</Subtit>
        <Desc>
        이용자의 개인정보를 취급함에 있어 개인정보의 분실, 도난, 누출, 변조 또는 훼손을 방지하기 위하여 아래와 같은 기술적?관리적 조치를 하고 있습니다.<br/>
        7-1. 이용자의 개인정보는 실명확인절차에 의해 보호되고 있습니다.<br/>
        7-2. 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.<br/>
        7-2-1. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있습니다.<br/>
        7-2-2. 최신 백신프로그램을 이용하여 이용자의 개인정보나 자료가 유출되거나 손상되지 않도록 방지합니다.<br/>
        7-2-3. SSL 암호화 알고리즘을 이용하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.<br/>
        7-2-4. 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 안정성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.<br/>
        7-3. 케이지비(주)는 이용자의 개인정보보호의 중요성을 인식하고 있으며 이를 위해 개인정보처리자를 최소한으로 제한하고 있으며 개인정보보호책임자가 처리자를 대상으로 주기적인 교육을 실시하여 개인정보보호를 위해 최선을 다하고 있습니다.<br/>
        또한, 본 방침에 명시된 이행사항 및 관련 직원의 준수여부를 정기적으로 점검하여 위반내용이 있는 경우 이를 시정 또는 개선하고 기타 필요한 조치를 취하도록 하고 있습니다.<br/>
        </Desc>
        <Subtit>8. 개인정보 관리책임자의 성명, 부서 및 연락처</Subtit>
        <Desc>
        8-1. 케이지비(주)는 이용자의 개인정보 관련 문의사항 및 불만처리 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br/>
        개인정보 보호책임자 : 기획실 김주묵 과장 (031-799-3208) plan@kgb.co.kr<br/>
        8-2. 기타 개인정보 침해에 대한 신고나 상담이 필요한 경우 아래 기관에 문의 가능합니다.<br/>
        8-2-1. 개인정보 침해신고센터(privacy.kisa.or.kr / 국번 없이 118)<br/>
        8-2-2. 경찰청 사이버안전국(cyberbureau.police.go.kr / 국번 없이 182)<br/>
        </Desc>
        <Subtit>9. 개정 전 고지사항</Subtit>
        <Desc>
        본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에 ‘공지사항’을 통해 사전 공지를 할 것입니다. 다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 공지하겠습니다.<br/>
        9-1. 개인정보처리방침 변경 공고일자 : 2019년 01월 10일<br/>
        9-2. 개인정보처리방침 시행일자 : 2019년 01월 17일<br/>
        9-3. 이전 개인정보취급방침 보기(2016년 1월27일~2019년 1월 16일)
        </Desc>
    </Wrapper>
  );
}

export default Privacy;