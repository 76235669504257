export const M_SC_GETDATA = 'M_SC_GETDATA';
export const M_SC_LOADING = 'M_SC_LOADING';
export const M_SC_ERROR = 'M_SC_ERROR';


export const D_SC_GETDATA = 'D_SC_GETDATA';
export const D_SC_LOADING = 'D_SC_LOADING';
export const D_SC_ERROR = 'D_SC_ERROR';
 

export const D_SC_GETDATA2 = 'D_SC_GETDATA2';
export const D_SC_LOADING2 = 'D_SC_LOADING2';
export const D_SC_ERROR2 = 'D_SC_ERROR2';


export const SONDAY_SUCCESS = 'SONDAY_SUCCESS';
export const SONDAY_LOADING = 'SONDAY_LOADING';
export const SONDAY_ERROR = 'SONDAY_ERROR';
export const SONDAY_INIT = 'SONDAY_INIT';



export const EDU_SUBMIT_SUCCESS = "EDU_SUBMIT_SUCCESS";
export const EDU_SUBMIT_LOADING = "EDU_SUBMIT_LOADING";
export const EDU_SUBMIT_ERROR = "EDU_SUBMIT_ERROR";
export const EDU_SUBMIT_INIT = "EDU_SUBMIT_INIT";