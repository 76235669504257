export const AS_GET_LIST_SUCCESS = "AS_GET_LIST_SUCCESS";
export const AS_GET_LIST_LOADING = "AS_GET_LIST_LOADING";
export const AS_GET_LIST_ERROR = "AS_GET_LIST_ERROR";


export const AS_GET_LIST_CONCAT_SUCCESS = "AS_GET_LIST_CONCAT_SUCCESS";
export const AS_GET_LIST_CONCAT_LOADING = "AS_GET_LIST_CONCAT_LOADING";
export const AS_GET_LIST_CONCAT_ERROR = "AS_GET_LIST_CONCAT_ERROR";


export const AS_GET_DETAIL_SUCCESS = "AS_GET_DETAIL_SUCCESS";
export const AS_GET_DETAIL_LOADING = "AS_GET_DETAIL_LOADING";
export const AS_GET_DETAIL_ERROR = "AS_GET_DETAIL_ERROR";

//댓글등록
export const AS_POST_COMMENT_SUCCESS = "AS_POST_COMMENT_SUCCESS";
export const AS_POST_COMMENT_LOADING = "AS_POST_COMMENT_LOADING";
export const AS_POST_COMMENT_ERROR = "AS_POST_COMMENT_ERROR";
export const AS_POST_COMMENT_INIT = "AS_POST_COMMENT_INIT";