export const SELFTEST_SUCCESS = "SELFTEST_SUCCESS";
export const SELFTEST_ERROR = "SELFTEST_ERROR";
export const SELFTEST_LOADING = "SELFTEST_LOADING";


//자가평가 결과 상세보기 
export const SELFTEST_RESULT_SUCCESS = "SELFTEST_RESULT_SUCCESS";
export const SELFTEST_RESULT_ERROR = "SELFTEST_RESULT_ERROR";
export const SELFTEST_RESULT_LOADING = "SELFTEST_RESULT_LOADING";

//자가평가 질문지 받아오기 
export const SELFTEST_QUESTION_SUCCESS = "SELFTEST_Q  UESTION_SUCCESS";
export const SELFTEST_QUESTION_ERROR = "SELFTEST_QUESTION_ERROR";
export const SELFTEST_QUESTION_LOADING = "SELFTEST_Q  UESTION_LOADING";

//자가평가 결과 보내기
export const SELFTEST_POST_SUCCESS = "SELFTEST_POST_SUCCESS";
export const SELFTEST_POST_ERROR = "SELFTEST_POST_ERROR";
export const SELFTEST_POST_LOADING = "SELFTEST_POST_LOADING";
