export const EDUATTEND_LIST_SUCCESS = "EDUATTEND_LIST_SUCCESS";
export const EDUATTEND_LIST_LOADING = "EDUATTEND_LIST_LOADING";
export const EDUATTEND_LIST_ERROR = "EDUATTEND_LIST_ERROR";

export const EDUPOINT_USE_SUCCESS = "EDUPOINT_USE_SUCCESS";
export const EDUPOINT_USE_LOADING = "EDUPOINT_USE_LOADING";
export const EDUPOINT_USE_ERROR = "EDUPOINT_USE_ERROR";



//교육 설문 리스트
export const EDUSURVEY_LIST_SUCCESS = "EDUSURVEY_LIST_SUCCESS";
export const EDUSURVEY_LIST_LOADING = "EDUSURVEY_LIST_LOADING";
export const EDUSURVEY_LIST_ERROR = "EDUSURVEY_LIST_ERROR";


export const EDUISU_LIST_SUCCESS = "EDUISU_LIST_SUCCESS";
export const EDUISU_LIST_LOADING ="EDUISU_LIST_LOADING";
export const EDUISU_LIST_ERROR ="EDUISU_LIST_ERROR";

export const EDU_MANUAL_SUCCESS = "EDU_MANUAL_SUCCESS";
export const EDU_MANUAL_LOADING = "EDU_MANUAL_LOADING";
export const EDU_MANUAL_ERROR = "EDU_MANUAL_ERROR";
export const EDU_MANUAL_INIT = "EDU_MANUAL_INIT";
