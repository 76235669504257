export const MSG_RCV_LIST_SUCCESS = "MSG_RCV_LIST_SUCCESS";
export const MSG_RCV_LIST_LOADING = "MSG_RCV_LIST_LOADING";
export const MSG_RCV_LIST_ERROR = "MSG_RCV_LIST_ERROR";


export const MSG_SEND_LIST_SUCCESS = "MSG_SEND_LIST_SUCCESS";
export const MSG_SEND_LIST_LOADING = "MSG_SEND_LIST_LOADING";
export const MSG_SEND_LIST_ERROR = "MSG_SEND_LIST_ERROR";

export const MSG_DEL_SUCCESS = "MSG_DEL_SUCCESS";
export const MSG_DEL_LOADING = "MSG_DEL_LOADING";
export const MSG_DEL_ERROR = "MSG_DEL_ERROR";
export const MSG_DEL_INIT = "MSG_DEL_INIT";

//주소록 검색
export const MSG_SEARCH_ADD_SUCCESS = "MSG_SEARCH_ADD_SUCCESS";
export const MSG_SEARCH_ADD_LOADING = "MSG_SEARCH_ADD_LOADING";
export const MSG_SEARCH_ADD_ERROR = "MSG_SEARCH_ADD_ERROR";


//쪽지보내기
export const MSG_SEND_SUCCESS = "MSG_SEND_SUCCESS";
export const MSG_SEND_LOADING = "MSG_SEND_LOADING";
export const MSG_SEND_ERROR = "MSG_SEND_ERROR";
export const MSG_SEND_INIT = "MSG_SEND_INIT";