import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Button from '../commonStyle/Button';
import { Gutter, FlexBox, ChangeFont } from '../commonStyle';
import Row from './Row';
import { useStore } from 'react-redux';
import { sendBillInfo } from '../../redux/thunkFn/bill.thunk';
import { useDispatch, useSelector } from 'react-redux';

const caution = css`
  ${({ top, left, right }) => css`
    content:'';
    position:absolute;
    background: url(${process.env.PUBLIC_URL + '/images/ico_caution.png'});
    background-size: 100%;
    width:12px;
    height:12px;
    top: ${top};
    left: ${left};
    right: ${right};
  `}
`;

const Wrapper = styled.div`
    background: #f3f7fb;
    border: 1px solid #dfe5ea;
    border-radius:4px;
    margin-bottom:20px;
    ${Gutter('10px 15px')};

`;
const Title = styled.div`
    display:inline-block;
    position:relative;
    margin-bottom:8px;
    padding-right:16px;
    font-size:14px;
    font-weight:bold;
    &.caution:after{
        ${caution}
    }
`;
const InfoList = styled.ul`
    li{
        position:relative;
        font-size:10px;
        padding-left:8px;
        margin-bottom:5px;
        &:before{
            content:'';
            position:absolute;
            width:2.5px;
            height:2.5px;
            top: 6px;
            left:0;
            background:#CED5DB;
        }
    }

`;
const PayWrap = styled.div`
    ${FlexBox()};
    margin :10px 0;
    width:100%;
    align-items: right;
    justify-content: right;
`;
const PayWrapHide = styled.div`
    ${FlexBox()};
    margin :10px 0;
    width:100%;
    align-items: right;
    justify-content: right;
    display:none;
`;
const Price = styled.input`
    width:100%;
    margin-right:8px;
    ${ChangeFont(true, 200)};
    font-size:18px;
    text-align:right;
    border-bottom: 1px solid #DFE5EA;
    &:after{
      content:'원';
      position:absolute;
      left:0;top:0;
  }
`;

const InPrice = styled.input`
    width:90%;
    margin-right:8px;
    ${ChangeFont(true, 200)};
    font-size:18px;
    text-align:right;
    border-bottom: 1px solid #DFE5EA;
    &:after{
      content:'원';
      position:absolute;
      right:-20px;top:0;
      border:1px solid #FF0000;
      width:20px;height:20px;
  }
`;
const PriceUnit = styled.div`
    width:20px;
    margin-right:8px;    
    font-size:18px;
    text-align:right;    
    font-weight:bold;
`;
const Caution = styled.div`
    margin: 25px 0 5px;
    position:relative;
    padding-left:16px;
    font-size: 11px;
    font-weight:bold;
    &:before{
        ${caution}
    }
`;

const BtnCredit = styled.div`
    padding:5px 20px;
    position:relative;
    padding-left:16px;
    font-size: 14px;
    border-radius:5px;
    font-weight:bold;
    display:inline-block;
    background-color:#0000FF;
    color:#FFFFFF;    
`;
const PayInfo = {
  'title':'',
  'price': '',
  'orderId' : '',
  'rurl': '',
}
var pg_code = "";
var pg_code_asp = "";
const Payment = (effect,deps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "https://code.jquery.com/jquery-1.12.4.min.js";
    const iamport = document.createElement("script");
    iamport.src = "https://cdn.iamport.kr/js/iamport.payment-1.1.7.js";
    document.head.appendChild(jquery);
    document.head.appendChild(iamport);

    return () => {
      document.head.removeChild(jquery);
      document.head.removeChild(iamport);
    }
  },[]);
  

  const onClickAlert = () => {
    alert('결제할 금액이 없습니다.');
  }

  const onClickPayment = () => {
    const user = JSON.parse(localStorage.getItem('user'))  ;
    const { IMP } = window;
    IMP.init('imp24610043');
    //dispatch(sendBillInfo(1000,'card','KCP'));    
    //console.log(" B " + user.brand);
    
    var price = PayInfo.price.replace(/,/g,'');
    var inPrice = document.getElementById("rpay").value;
    price = parseInt(price,10);
    if(inPrice === ""){
      alert('결제할 금액을 입력해 주세요.');
      document.getElementById("rpay").focus();
      return;
    }
    inPrice = parseInt(inPrice,10);
    if(inPrice > price){
    //  alert('결제금액이 잘못되었습니다.');
    //  return;
    }
    price = inPrice;
    
    if(user.brand ==="YES2404" || user.brand ==="KGB포장이사서비스" || user.brand ==="협력업체"){
      pg_code = "html5_inicis.MOIkgbcore";
      pg_code_asp = "inisys"
    }else if( user.brand === "이사이사" || user.brand === "용달캡" ){
      pg_code = "kcp";
      pg_code_asp = "KCP"
    }
   
    //
    const data = {
      pg: pg_code,
      pg_code: pg_code_asp,  
      pay_method: "CARD",
      merchant_uid: PayInfo.orderId,
      name: PayInfo.title,
      amount: price,
      buyer_email: "",
      buyer_name: user.name,
      buyer_tel: user.brand_tel,
      buyer_addr: "",
      buyer_postcode: ""  
      
    };
    
    IMP.request_pay(data,callback);
  }

 


  

  const callback = (response) => {
    console.log(response);
    const {success, error_msg,imp_uid,merchant_uid,pay_method,paid_amount,status} = response;
    
    if(success){
      //alert('success');      
      dispatch(sendBillInfo(paid_amount,pay_method,pg_code));
      //alert('정상적으로 처리되었습니다.');
      //window.document.location.reload();
    }else{
      alert('failed' + error_msg);
    }
  }
 
  var price = 0;
  if(PayInfo.price !== ""){
    PayInfo.price = PayInfo.price.replace(",",'');
    price = parseInt(price,10);
    console.log("PRICER " + PayInfo.price);
  }
  //const [price, setValue] = useState(price);
  const onChangePrice = e => {
    //this.setState({ value: e.target.value });
    console.log("PRICE : " + e.target.value);
   // setValue(e.target.value);
  };
  
  return (
    <>   
      <h1>결제금액 입력</h1>
      <PayWrap>
        <InPrice type="tel" id="rpay" name="rpay" defaultValue={PayInfo.price} placeholder="결제금액을 입력해주세요" onChange={onChangePrice}></InPrice>
        <PriceUnit>원</PriceUnit>
      </PayWrap>    
      <PayWrap>
      <BtnCredit onClick={onClickPayment}>결제하기</BtnCredit>
      </PayWrap>
    </>
  );
  
}



const PayBox = ({isCard,type,money,title,orderId,rurl}) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [count, setCount] = useState("");
    const [brand, setBrand] = useState("");

    PayInfo.title = title;
    if(money != undefined){
      PayInfo.price = money;
    }
    if(orderId != undefined){
      PayInfo.order_id = orderId;
    }
    if(rurl != undefined){
      PayInfo.rurl = rurl;
    }
    
    useEffect(() => {
      
      if(type === "cart"){
        if(user.brand ==="YES2404"){
          setCount("485-003506-01-117");
          setBrand("케이지비(주)");
        }else if( user.brand === "이사이사"){
          setCount("485-012612-01-070");
          setBrand("예스2424(주)");
        }else if( user.brand === "KGB포장이사서비스"){
          setCount("485-003506-01-117");
          setBrand("케이지비(주)");
        }else if( user.brand === "용달캡"){
          setCount("485-012612-01-070");
          setBrand("예스2424(주)");
        }else if( user.brand === "협력업체"){
          setCount("");
          setBrand("");
        } else {
          setCount("");
          setBrand("");
        }
      }else{
        
        if(user.brand ==="YES2404"){
          setCount("485-003506-01-100");
          setBrand("KGB(주)");
        }else if( user.brand === "이사이사"){
          setCount("485-012612-01-055");
          setBrand("예스2424(주)");
        }else if( user.brand === "KGB포장이사서비스"){
          setCount("485-003506-01-099");
          setBrand("KGB(주)");
        }else if( user.brand === "용달캡"){
          setCount("485-012612-04-091");
          setBrand("예스2424(주)");
        }else if( user.brand === "협력업체"){
          setCount("");
          setBrand("");
        } else {
          setCount("");
          setBrand("");
        }
      }
        
    }, [])
    return (
        <Wrapper>
            { isCard ? 
            <>
            <Title className={isCard ? 'caution': ''} top='3px' right='0px' >신용카드 결제</Title>
            <InfoList>
                <li>청구금액은 부가세 포함한 금액입니다.</li>                
                <li>일부분만 결제하실려면 금액을 직접 입력해 주십시오</li>
            </InfoList>            
            <PayWrapHide>
                <Price type="text" value={money}></Price>
                
            </PayWrapHide>
            <Payment></Payment>
            </>
            :
            <>
            <Title>현금 결제안내</Title>
            <Row dt="결제계좌" dd={count} ddWeight='bold'>{count}</Row>
            <Row dt="은행" dd={"기업은행"} ddWeight='bold'>기업은행</Row>
            <Row dt="예금주" dd={brand} ddWeight='bold'>{brand}</Row>
            <Caution top='3px' left='0px'>현금 결제 전 꼭 확인해주세요 !</Caution>
            <InfoList>
                <li>반드시 실명 또는 가맹점 사업자명으로 입금해주시기 바랍니다.</li>
                <li>관리비고지서를 못받았을때는 담당자에게 알려주시기 바랍니다.</li>
            </InfoList>
            </>
            }
        </Wrapper>
    );
  };

export default PayBox;
